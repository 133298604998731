import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Markdown from 'react-markdown';
import LayoutContainer from '../containers/layout';
import PageBackgroundImageComponent from '../components/page-background-image';
import PageContentComponent from '../components/page-content';
import PageTitleComponent from '../components/page-title';
import DividerComponent from '../components/divider';
import ButtonSeeAlsoComponent from '../components/button-see-also';
import { GatsbyImage } from 'gatsby-plugin-image';

const AboutUsPage = () => {
  const data = useStaticQuery(query);

  return (
    <LayoutContainer title={data.strapiAboutUs.title} description={data.strapiAboutUs.description}>
      <PageTitleComponent showBackground={true}>
        <div className="text-4xl lg:text-5xl text-black">{data.strapiAboutUs.title}</div>
      </PageTitleComponent>

      <PageBackgroundImageComponent image={data.strapiAboutUs.backgroundImage.localFile.childImageSharp.gatsbyImageData} />

      <PageContentComponent showBackground={true}>
        <section className="mb-12">
          <div className="flex">
            <div className="w-full lg:w-3/5 lg:pr-20">
              <div className="w-full text-4xl text-blue-600 mb-12">{data.strapiAboutUs.subtitle}</div>
              <Markdown className="no-widows w-full markdown">{data.strapiAboutUs.description}</Markdown>
            </div>
            <div className="relative hidden lg:block w-2/5 pl-20 pt-4">
              <div className="absolute -top-16 left-0 w-0.5 h-48 bg-gray-200"></div>
              <div className="henderson-font text-black text-xs font-semibold mb-4">ZOBACZ TEŻ:</div>
              <Link to="/wspolpraca" className="block">
                <ButtonSeeAlsoComponent title="Współpraca" />
              </Link>
            </div>
          </div>
        </section>

        <section className="mb-12">
          <DividerComponent />
        </section>

        <section>
          <div className="text-4xl text-blue-600 mb-12">Zespół</div>
          {data.strapiAboutUs.teams.map((el, i) => (
            <div key={i} className="mb-12">
              <div className="text-3xl mb-12">{el.title}</div>
              <div className="flex flex-col lg:flex-row justify-between">
                <div className="w-full lg:w-1/2 mb-12 lg:mb-0">
                  <Markdown className="no-widows w-full markdown">{el.description}</Markdown>
                </div>
                <div className="w-full lg:w-1/4">
                  <GatsbyImage image={el.image.localFile.childImageSharp.gatsbyImageData} alt="" />
                </div>
              </div>
            </div>
          ))}
        </section>
      </PageContentComponent>
    </LayoutContainer>
  );
};

const query = graphql`
  query {
    strapiAboutUs {
      title
      subtitle
      description
      backgroundImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: AUTO, quality: 92)
          }
        }
      }
      teams {
        title
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: AUTO, quality: 92)
            }
          }
        }
      }
    }
  }
`;

export default AboutUsPage;
